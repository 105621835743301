.textarea-input {
  width: calc(100% - 22px);
  margin: 4px auto 10px;
  display: block;
  padding: 7px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;

  &:focus {
    outline: #aaa;
  }

  &.h-200 {
    height: 200px;
  }
}
