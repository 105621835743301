.feed-comment {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .profile-image {
    width: 50px;
    margin-right: 10px;

    img {
      height: 50px;
      border-radius: 100%;
    }
  }

  .comment-body {
    background-color: #ededed;
    border-radius: 10px;
    width: calc(90% - 75px);
    padding: 10px;
    text-align: left;
    color: #444;

    .user-name {
      display: block;
      color: #000;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}