.post-list {
  list-style: none;
  width: 60%;
  height: calc(100% - 30px);
  padding: 10px 0 20px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 75%;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
}