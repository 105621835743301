.feed-edit {
  display: flex;
  justify-content: center;
  align-items: center;

  .create-post-button {
    width: calc(100% - 20px);
    height: 40px;
    border-radius: 20px;
    border: solid #999 1px;
    text-align: left;
    margin-left: 10px;
    padding-left: 10px;
    background-color: #f2f2f2;
    font-weight: 500;
    font-size: 14px;
    color: #444;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 38px;
  }
}
