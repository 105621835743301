.comments-modal {
  position: relative;

  .close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    color: #000;
    cursor: pointer;
  }
}

.image-preview-modal {
  padding: 0 !important;
  width: fit-content !important;
  position: relative;
  height: fit-content;

  img {
    height: 80vh;
    max-width: 90vw;
    margin: 0;
  }

  .close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    color: white;
    font-size: 20px;
    border-radius: 100%;
    background-color: black;
    padding: 5px;
    cursor: pointer;
  }
}

.post-card {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 10px;
  margin: 20px auto;

  .profile-header {
    height: 60px;
    display: inline-block;

    img {
      width: 60px;
      border-radius: 100%;
    }
  }

  .profile-info {
    width: calc(100% - 70px);
    display: inline-flex;
    vertical-align: top;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 10px;

    .datetime {
      color: #ccc;
      font-size: 14px;
    }

    .username {
      font-size: 17px;
    }
  }

  .post-body {
    margin-top: 20px;

    img {
      width: 100%;
      border-radius: 20px;
      object-fit: cover;
      max-height: 300px;
      object-position: 0% 0%;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
      margin-bottom: 20px;
    }
  }

  .toolbar {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    font-size: 20px;
    color: #888;

    .likes {
      display: block;
      font-size: 15px;
      padding-bottom: 10px;
    }

    svg {
      margin-left: 10px;
      display: inline-block;
      cursor: pointer;

      &.active {
        color: #e81403;
      }

      &:hover {
        color: #e64e44;
      }

      &:active {
        color: #832019;
      }

      &:first-of-type {
        margin: 0;
      }
    }
  }
}