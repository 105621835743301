.input-wrapper {
  width: 90%;
  margin: 0 auto;

  label {
    display: block;
    font-size: 13px;
    text-align: left;
    margin-top: 15px;
  }

  .text-input {
    width: calc(100% - 22px);
    margin: 5px auto 5px;
    display: block;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #bfbfbf;

    &:focus {
      outline: #aaa auto 1px !important;
    }
  }
}
