.modal-wrapper {
  background-color: transparentize(#000, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-box {
    background-color: white;
    width: 500px;
    padding: 10px 20px 20px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 10px;
    text-align: center;
    max-height: 95vh;
    overflow-y: auto;

    .content {
      height: fit-content;
    }

    .modal-loader {
      height: 75px;
    }

    form {
      text-align: left;
      margin-top: 20px;
    }

    &.w-700 {
      width: 700px;

      form {
        width: 100%;
        margin: 20px auto 0;
      }
    }

    &.w-1200 {
      width: 1200px;
    }

    &.no-padding {
      padding: 0px;
    }

    h2.title {
      margin: 10px 0 12px;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0.3px;
    }

    h4.description {
      font-weight: 400;
      color: #444;
      margin: 0;
    }

    .button-footer {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .raw {
        color: #000;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}
